import { myCourses } from 'API/course'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import './courses.scss'
import Select from 'react-select'
import { RetType, Course } from './CourseComponent'
import { getTracking } from 'API/user'
import { NonUndefined, PromiseType } from 'utility-types'
import { useAppSelector } from 'redux/hooks'
import { CourseType } from 'types/CourseAdditionMetaData'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
const url = typeof window !== 'undefined' ? window.location.href : ''
export const online = {
	_id: 'Online-Course-Test-1002',
	course_tag: 'Academics',
	createdAt: new Date(1630911369158),
	created_by: '',
	description: '',
	end_date: new Date(1630911369158),
	name: 'Online Course Test',
	published: true,
	start_date: new Date(1630911369158),
	uid: 'Online-Course-Test-1002',
	updatedAt: new Date(1630911369158),
	Institute: '',
}
// const filterType = () => {
// 	if (url.includes('8000')) {
// 		return [
// 			{
// 				id: 1,
// 				value: 'all',
// 				label: 'All',
// 			},
// 			{
// 				id: 2,
// 				value: 'JNMC',
// 				label: 'JNMC',
// 			},
// 		]
// 	}
// }
let filterType: any = []
if (url.includes('jnmc')) {
	filterType = [
		{
			id: 1,
			value: 'all',
			label: 'All',
		},
		{
			id: 2,
			value: 'JNMC',
			label: 'JNMC',
		},
		{
			id: 3,
			value: 'DMMC',
			label: 'DMMC',
		},
	]
}
if (url.includes('spdc')) {
	filterType = [
		{
			id: 1,
			value: 'all',
			label: 'All',
		},
		{
			id: 2,
			value: 'SPDC',
			label: 'SPDC',
		},
	]
}
if (url.includes('dmimsu.adcclms')) {
	filterType = [
		{
			id: 1,
			value: 'all',
			label: 'All',
		},
		{
			id: 2,
			value: 'MGAC',
			label: 'MGAC',
		},
		{
			id: 3,
			value: 'DMCP',
			label: 'DMCP',
		},
		{
			id: 4,
			value: 'FEAT',
			label: 'FEAT',
		},
		{
			id: 5,
			value: 'RNPC',
			label: 'RNPC',
		},
		{
			id: 6,
			value: 'SRMMCON',
			label: 'SRMMCON',
		},
		{
			id: 7,
			value: 'CDOE',
			label: 'CDOE',
		},
		{
			id: 8,
			value: 'FOCM',
			label: 'FOCM',
		},
		{
			id: 9,
			value: 'AHS',
			label: 'AHS',
		},
		{
			id: 10,
			value: 'Others',
			label: 'Others',
		},
	]
}
if (url.includes('dmimsuonline')) {
	filterType = [
		{
			id: 1,
			value: 'all',
			label: 'All',
		},
		{
			id: 2,
			value: 'SPDC',
			label: 'SPDC',
		},
		{
			id: 3,
			value: 'FOCM',
			label: 'FOCM',
		},
		{
			id: 4,
			value: 'AHS',
			label: 'AHS',
		},
		{
			id: 5,
			value: 'FEAT',
			label: 'FEAT',
		},
		{
			id: 6,
			value: 'Others',
			label: 'Others',
		},
	]
}
if (url.includes('localhost')) {
	filterType = [
		{
			id: 1,
			value: 'all',
			label: 'All',
		},
		{
			id: 2,
			value: 'JNMC',
			label: 'JNMC',
		},
		{
			id: 3,
			value: 'SPDC',
			label: 'SPDC',
		},
		{
			id: 4,
			value: 'MGAC',
			label: 'MGAC',
		},
		{
			id: 6,
			value: 'DMCP',
			label: 'DMCP',
		},
		{
			id: 7,
			value: 'FEAT',
			label: 'FEAT',
		},
		{
			id: 8,
			value: 'RNPC',
			label: 'RNPC',
		},
		{
			id: 9,
			value: 'DMMC',
			label: 'DMMC',
		},
		{
			id: 10,
			value: 'SRMMCON',
			label: 'SRMMCON',
		},
		{
			id: 11,
			value: 'CDOE',
			label: 'CDOE',
		},
		{
			id: 12,
			value: 'FOCM',
			label: 'FOCM',
		},
		{
			id: 13,
			value: 'AHS',
			label: 'AHS',
		},
		{
			id: 14,
			value: 'Others',
			label: 'Others',
		},
	]
}
const Courses: React.FC = () => {
	const [tracking, setTracking] = useState<NonUndefined<PromiseType<ReturnType<typeof getTracking>>>>()
	React.useEffect(() => {
		const run = async () => {
			setTracking(await getTracking())
		}
		run()
	}, [])

	const [showArchived, setShowArchived] = useState(false)
	const [selected, setSelected] = useState('all')
	const [courses, setCourses] = useState<RetType>({
		studying: [
			// {
			// 	course: online,
			// },
		],
		teaching: [],
	})
	useEffect(() => {
		const run = async () => {
			const courses = await myCourses()
			setCourses(courses)
		}
		run()
	}, [])

	const [teachingArr, setTeachingArr] = useState<
		{
			course: CourseType
		}[]
	>([])

	const search = useAppSelector((state) => state.ApplicationReducer.search)
	const [query, setquery] = useState('')
	React.useEffect(() => {
		if (courses) {
			const tempArr = courses?.teaching
			console.log(tempArr)
			if (search === '') {
				setTeachingArr(tempArr)
			} else {
				setTeachingArr(tempArr.filter((elem) => search.split('=')?.[1]?.toUpperCase() !== elem.course?.course_tag?.toUpperCase()))
			}
		}
	}, [courses, search])
	return (
		<>
			<div className='container'>
				<div className='courses-page mx-2'>
					<h1>{search && search !== '' && search.split('=')?.[1]?.toUpperCase()}</h1>
				</div>

				{courses && (
					<>
						{/* <div className=''></div> */}
						<div className='courses-page mx-2'>
							<>
								<h3>Teaching</h3>
								<div className='my-4 row'>
									<div
										onClick={() => {
											setShowArchived(false)
										}}
										className={'btn_1 px-2 ' + (showArchived === false ? 'active_focus ' : '')}
									>
										Current
									</div>

									<div
										onClick={() => {
											setShowArchived(true)
										}}
										className={'btn_1 px-2 ' + (showArchived === true ? 'active_focus ' : '')}
									>
										Past
									</div>
								</div>
								<div className=' mt-4 mb-4'>
									<div className='row'>
										<div style={{ margin: 'auto' }} className='col-6'>
											{/* <input type='text' placeholder='search' onChange={(e) => setquery(e.target.value)} /> */}
											<Form.Control
												type='search'
												onChange={(e) => setquery(e.target.value)}
												placeholder='Search'
												className='me-2'
												aria-label='Search'
											/>
										</div>
										<div className='col-6'>
											<Select
												className='my-3'
												options={filterType}
												// value={questionTypes[elem.type - 1]}
												onChange={(e) => {
													console.log(e?.value)
													if (e?.value) setSelected(e?.value)
												}}
											></Select>
										</div>
									</div>

									<div className='row'>
										{filterType.map((ele: any, indx: any) => {
											return (
												selected === ele.value &&
												teachingArr
													.filter((elem) => {
														return showArchived === true
															? elem.course.is_archived === true
															: elem.course.is_archived === false
													})

													?.filter((ele) => ele.course.name.toLowerCase().includes(query.toLowerCase()))
													?.map((elem) => {
														// console.log('hey', elem.course.name)
														const exists = tracking?.filter((track) => track.course.uid === elem.course.uid)

														{
															return (
																elem.course.Institute === ele.value && (
																	<>
																		<Course
																			// key={indx}
																			tracking_0={
																				exists?.[0] &&
																				new Date(exists[0].available_till).getTime() > Date.now()
																					? exists[0]
																					: undefined
																			}
																			tracking_1={
																				exists?.[1] &&
																				new Date(exists[1].available_till).getTime() > Date.now()
																					? exists[1]
																					: undefined
																			}
																			course={elem.course}
																		/>
																	</>
																)
															)
														}
													})
											)
										})}

										{selected === 'all' &&
											teachingArr
												.filter((elem) => {
													console.log(elem)
													return showArchived === true
														? elem.course.is_archived === true
														: elem.course.is_archived === false
												})
												?.filter((ele) => ele.course.name.toLowerCase().includes(query.toLowerCase()))
												?.map((elem) => {
													const exists = tracking?.filter((track) => track.course.uid === elem.course.uid)

													return (
														<>
															<Course
																tracking_0={
																	exists?.[0] && new Date(exists[0].available_till).getTime() > Date.now()
																		? exists[0]
																		: undefined
																}
																tracking_1={
																	exists?.[1] && new Date(exists[1].available_till).getTime() > Date.now()
																		? exists[1]
																		: undefined
																}
																course={elem.course}
															/>
														</>
													)
												})}
									</div>
								</div>
							</>

							{courses.studying && (
								<>
									<h3>Studying</h3>
									<div className=' mt-4 mb-4'>
										<div className='row'>
											{[...courses.studying]
												.filter((elem) => {
													return showArchived === true
														? elem.course.is_archived === true
														: elem.course.is_archived === false
												})
												?.map((elem) => {
													const exists = tracking?.filter((track) => track.course.uid === elem.course.uid)
													return (
														<>
															<Course tracking_0={exists?.[0]} tracking_1={exists?.[1]} course={elem.course} />
														</>
													)
												})}
										</div>
									</div>
								</>
							)}
						</div>
					</>
				)}
			</div>
		</>
	)
}
export default Courses
